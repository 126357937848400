
import './header.css'

function Header() {
  return (
    <div className="headerWrapper">
      <div className="name">Alirie Gray</div>
      <div className="paragraphContainer">
        <p className="headerParagraph"> I am a full-stack developer based in the Los Angeles area. I have industry experience in back-end development with Golang and Node as well as front-end development in React. I have also worked as an elementary school computer lab teacher and private tutor for computer science. </p>
        <p className="headerParagraph"> My latest projects are visual novels built using the Ren'Py engine: <a href="https://knights-of-viviane.itch.io/meowain-the-tuna">Meowain the Tuna</a>, made in one week with a small team for a game jam, and  <a href="https://twiceasbright.games/">Conduit of the Miracle</a>, a personal project I started to combine my love of programming and creative writing.</p>
      </div> 
    </div>
  );
}

export default Header;
