import './App.css';
import Header from './Header'
import Contact from './Contact'
import portrait from './self.jpg'

function App() {
  return (
    <div className="container">
      <Header />
      <img className="portrait" src={portrait}/>
      <hr className="horizontal" />
      <Contact />
    </div>
  );
}

export default App;
