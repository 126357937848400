
function Contact() {
    return (
      <div className="contactWrapper">
        <div className="contactInfo">
          <a href="mailto: alirie.gray@gmail.com"> alirie.gray@gmail.com </a>
        </div>
        <div className="contactInfo">
          <a href="https://github.com/AlirieGray" target="_blank" rel="noreferrer noopener"> github.com/aliriegray </a>
        </div>
        <div className="contactInfo">
          <a href="https://www.linkedin.com/in/alirie-gray/" target="_blank" rel="noreferrer noopener">linkedin.com/in/alirie-gray </a>
        </div>
      </div>
    );
  }
  
  export default Contact;
  